import "./alert.scss"
import classNames from "classnames"
import React from "react"
import Timer from "../../Modules/Timer";

const Alert = ( props ) => {
  const classnames = classNames("alert__wrapper", {
    // size
    "alert__regular": props.regular,
    "alert__icon": props.icon,
  });
  return (
    // <div className={classnames} dangerouslySetInnerHTML={{ __html: props.content }} />
    props.timer ?
      <div className={classnames} >
        <Timer /> {props.content}
      </div>
      :
      <div className={classnames} dangerouslySetInnerHTML={{ __html: props.content }} />
    
  )

}
export default Alert

