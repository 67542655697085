import React, { Component } from "react"
import Layout from "../components/Layout/layout"
import Wrapper from "../components/Layout/Wrapper/wrapper"
import PaymentForm from "../components/Form/PaymentForm"
import "../components/scss/text.scss"
import Nav from "../components/Form/MultiStepForm/Nav"
import { SideSumary } from "../components/Modules/SideSummary"
import {EncodedCookieDataStore} from "../services/storage/datastore/EncodedCookieDataStore"
import {Base64EncoderService} from "../services/storage/encoder/Base64EncoderService"
import {Base64DecoderService} from "../services/storage/decoder/Base64DecoderService"
import Form from "../data/payment"

class HomeTemplate extends Component {
  constructor(props) {
    super(props);
    const getOrder = new EncodedCookieDataStore({
      cookieName: 'address_token',
      decoderService: new Base64DecoderService(),
      encoderService: new Base64EncoderService(),
    });

    this.state = {
      application: {
        applicationName: "",
        applicationNumber: "",
        applicationReference:'',
        applicationPrice: {
          id: "",
          name: "",
          amount: ""
        },
        applicationReference: getOrder.reference
      }
    }
  }

  updateForm = (form) => {
    this.setState({
      form: form
    })
  }

  updateApplication = (form) => {
    this.setState({
      application: form
    })
  }

  render() {
    const paymentSteps = ["Form Complete", "Review", "Payment", "Form Received"]
    const formFields = Form.fields
    const formStructure = Form.structure
    const application = this.state.application
    const applicationTitle = application.applicationNumber && application.applicationName ? 
                              application.applicationNumber + " - " + application.applicationName + " Form" : undefined;

    return (
      <Layout hideMenuButton={false} headerTitle={applicationTitle} hideFooterLogo={true}>
        <Wrapper collapse rowWrap buttonsContainer>
          <Nav totalSteps={paymentSteps.length} stepNames={paymentSteps} currentStep={paymentSteps.length -1} />
        </Wrapper>
        <Wrapper isPayment>
          <PaymentForm form={formFields} structure={formStructure} updateForm={this.updateForm} updateApplication={this.updateApplication} application={this.state.application} />
          <SideSumary applicationPrice={application.applicationPrice.amount} applicationReference={application.applicationReference}   />
        </Wrapper>
      </Layout>
    )
  }
}

export default HomeTemplate
