import React from "react"
import "./errorMessage.scss"

export const ErrorMessage = props => {
  return (
    <div className="e-errorMessage">
      {props.content}
    </div>
  )
}
