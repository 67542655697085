import React, { Component } from "react"
import "./payForm.scss"
import "../../Form/Tooltip/tooltip.scss"
import TextInput, { InputMaskForm } from "../../Form/TextInput"
import { PaymentWithCardHandler } from "../../../services/api/PaymentWithCard/PaymentWithCardHandler"
import { GetApplicationNameHandler } from "../../../services/api/GetApplicationName/GetApplicationNameHandler"
import { GetPricesHandler } from "../../../services/api/GetPrices/GetPricesHandler"
import { EncodedCookieDataStore } from "../../../services/storage/datastore/EncodedCookieDataStore"
import { Base64EncoderService } from "../../../services/storage/encoder/Base64EncoderService"
import { Base64DecoderService } from "../../../services/storage/decoder/Base64DecoderService"
import mastercard from "../../../images/svg/mastercard.svg"
import amex from "../../../images/svg/amex.svg"
import visa from "../../../images/svg/visa.svg"
import { Loading } from "../../Modules/Loading/loading"
import { ErrorMessage } from "../../Form/ErrorMessage/errorMessage"
import { checkId } from "../../../services/sem/checkId"
import { TypeSelector } from "../../../services/componentSwitcher/TypeSelector"
import { Button } from "../../Layout/Buttons/buttons"
import {checkUtmParams} from "../../../services/sem/checkUtmParams";

const cards = [mastercard, amex, visa]
let valid = require("card-validator")

class PayForm extends Component {
  constructor(props) {
    super(props)

    const getOrder = new EncodedCookieDataStore({
      cookieName: "address_token",
      decoderService: new Base64DecoderService(),
      encoderService: new Base64EncoderService(),
    })
    const cookieUtm = new EncodedCookieDataStore({
      cookieName: "utm_param",
      decoderService: new Base64DecoderService(),
      encoderService: new Base64EncoderService(),
    })
    this.state = {
      applicationId: getOrder.order,
      orderReference: getOrder.reference,
      loading: true,
      cookie: cookieUtm,
    }

  }

  getApplicationPrice = () => {
    this.setState({
      loading: true,
    })
    const applicationPriceHandler = new GetPricesHandler({})
    applicationPriceHandler.customAction().then(res => {
      let form = this.props.application
      form.applicationPrice = {
        id: res.regularPrice.id,
        name: res.regularPrice.name,
        amount: res.regularPrice.amount,
      }

      this.props.updateApplication(form)
      this.setState({
        loading: false,
      })
    }).catch((body) => {
      console.log(body)
    })
  }

  getApplicationName = () => {
    this.setState({
      loading: true,
    })
    const applicationNameHandler = new GetApplicationNameHandler({})
    let data = {}
    data.reference = this.state.applicationId
    applicationNameHandler.customAction(data).then(res => {
      let form = this.props.application
      form.applicationName = res.application.formName
      form.applicationId = this.state.applicationId
      form.applicationNumber = res.application.formDescription
      form.applicationReference = res.application.reference
      this.props.updateApplication(form)
      this.setState({
        loading: false,
      })
    }).catch((body) => {
      console.log(body)
      window.location.href = "/"
    })
  }

  getApplicationData = () => {
    this.getApplicationName()
    this.getApplicationPrice()
  }

  componentDidMount() {
    checkUtmParams();

    let id = checkId()
    const application = this.props.application
    const getOrder = new EncodedCookieDataStore({
      cookieName: "address_token",
      decoderService: new Base64DecoderService(),
      encoderService: new Base64EncoderService(),
    })
    if (id !== undefined) {
      this.setState(this.setState({ loading: false }))
      this.setState({ applicationId: id }, () => {
        getOrder.order = this.state.applicationId
        application.applicationId = this.state.applicationId
        this.props.updateApplication(application)
        this.getApplicationData()
      })
    } else if (this.state.applicationId) {
      this.setState({}, () => this.setState({ loading: false }))
      this.getApplicationData()
    } else {
      this.setState({ loading: true })
      if (this.state.orderReference == undefined)  window.location.href = "/"
    }
  }

  updateField = event => {
    let formData = this.props.form
    formData.applicationId.value = this.props.application.applicationId

    if (event.target.type === "checkbox") {
      let value = event.target.value,
        name = event.target.name,
        field = formData[name]
      formData[event.target.name].value = TypeSelector(field.component, value, event.target.checked)

      // let extraFee = formData[event.target.name].value !== '0';
      // this.props.extraFee(extraFee);
    } else {
      formData[event.target.name].value = event.target.value
    }
    this.props.updateForm(formData)
    if (formData.config.validate === false) {
      this.formValidator(false)
    }
  }
  submitHandle = () => {
    const validate = this.formValidator(true)
    if (!validate) {
      return null
    }
    const form = this.props.form

    let data = Object.values(form).reduce((obj, field) => {
      if (field.name !== "config") {
        obj[field.name] = field.value
      }
      return obj
    }, {})

    this.setState({ loading: true })

    const orderHandler = new PaymentWithCardHandler({})
    const utmParams = (({ utm_campaign, utm_medium }) => ({
      utm_campaign,
      utm_medium,
    }))(this.state.cookie)

    Object.entries(utmParams).forEach(([utm, value]) => data[utm] = value)
    let priceId = this.props.application.applicationPrice.id
    data.price = priceId
    orderHandler.customAction(data).then(res => {
      window.location.href = "/thank-you"
    }).catch((body) => {
      let formError = this.props.form
      formError.config.error = true
      this.props.updateForm(formError)
      this.setState({
        loading: false,
      })
    })
  }

  formValidator = scrollErrors => {
    let formData = this.props.form
    formData.config.validate = true
    let currentError = ""
    const expirationDate = formData.cardExpiration.value.split("/")
    formData.cardExpirationMonth.value = expirationDate[0]
    formData.cardExpirationYear.value = expirationDate[1]
    const fieldValidator = this.fieldValidator
    Object.keys(formData).map(function(objectKey) {
      const eventMock = {
        target: {
          name: objectKey,
          value: formData[objectKey].value,
        },
      }
      if (!fieldValidator(eventMock)) {
        formData.config.validate = false
        if (currentError === "") {
          currentError = objectKey
        }
      }
    })

    if (currentError && scrollErrors) {
      this.scrollToRef(currentError)
    }

    this.props.updateForm(formData)
    return formData.config.validate
  }
  fieldValidator = event => {
    const objectKey = event.target.name
    const value = event.target.value
    let formData = this.props.form
    if (value === "") {
      formData[objectKey].validate = false
    } else {
      if (objectKey === "cardNumber") {
        const isValidNumber = valid.number(value)
        if (isValidNumber.isValid) {
          formData[objectKey].validate = true
        } else {
          formData[objectKey].validate = false
        }
      } else if (objectKey === "cardCvv") {
        const cardNumber = formData["cardNumber"].value.split("-").join("")
        const amex = /^3[47][0-9]{13}$/
        let isValidCVV = ""
        if (amex.test(cardNumber)) {
          isValidCVV = valid.cvv(value, 4)
        } else {
          isValidCVV = valid.cvv(value)
        }

        if (isValidCVV.isValid) {
          formData[objectKey].validate = true
        } else {
          formData[objectKey].validate = false
        }
      } else if (objectKey === "cardExpiration") {
        const isValidCVV = valid.expirationDate(value)
        if (isValidCVV.isValid) {
          formData[objectKey].validate = true
        } else {
          formData[objectKey].validate = false
        }
      } else {
        formData[objectKey].validate = true
      }

    }

    this.props.updateForm(formData)

    return formData[objectKey].validate

  }

  scrollToRef = ref => {
    window.scrollTo({
      top: document.getElementById(ref) && document.getElementById(ref).getBoundingClientRect().top + window.scrollY - 40,
      behavior: "smooth",
    })
  }

  render() {
    const form = this.props.form
    return (
      <form className="m-paymentForm">
        <div className="">
          {this.state.loading &&
          <Loading/>
          }
          {form.config.error &&
          <ErrorMessage content="Something’s not right. Please check the information and enter it again."/>
          }
          <div className="m-paymentForm-payment">
            <div className="m-form-group">
              <TextInput
                title="Full Name"
                name="cardHolder"
                label="Card Holder Name"
                class="input-full-payment"
                onChange={this.updateField}
                onBlur={this.fieldValidator}
                placeholder={`eg. John Doe`}
                value={form.cardHolder.value}
                errorMessage={form.cardHolder.error}
                validate={form.cardHolder.validate}
              />
              <InputMaskForm
                title="Card Number"
                name="cardNumber"
                maxlength="16"
                label="Card Number"
                placeholder="e.g.: 2111-1111-1111-1111"
                mask="9999-9999-9999-9999"
                class="input-full-payment"
                onChange={this.updateField}
                onBlur={this.fieldValidator}
                value={form.cardNumber.value}
                errorMessage={form.cardNumber.error}
                validate={form.cardNumber.validate}
              />
              <div className="m-paymentForm--row">
                <div className="m-paymentForm--input m-paymentForm--input__expirationDate">
                  <InputMaskForm
                    title="Expiration Number"
                    name="cardExpiration"
                    label="Expiration date"
                    placeholder="MM/YY"
                    mask="99/99"
                    class="input-payment-exp"
                    onBlur={this.fieldValidator}
                    onChange={this.updateField}
                    value={form.cardExpiration.value}
                    errorMessage={form.cardExpiration.error}
                    validate={form.cardExpiration.validate}
                  />
                </div>

                <div className="m-paymentForm--input m-paymentForm--input__ccv">
                  <InputMaskForm
                    title="CVV/CVC"
                    name="cardCvv"
                    label="CVV"
                    class="input-payment-exp"
                    mask="9999"
                    placeholder="123"
                    onBlur={this.fieldValidator}
                    onChange={this.updateField}
                    errorMessage={form.cardCvv.error}
                    validate={form.cardCvv.validate}
                    value={form.cardCvv.value}
                    help="Last 3 or 4 digits on the back of your card"
                  />
                </div>
              </div>
            </div>
            <div className="m-paymentForm-footer m-paymentForm-footer__payment">
              <Button primary data-qa="submit-form" label="Complete you order" align="center" nolink
                      onClick={this.submitHandle}/>
            </div>
          </div>
        </div>

      </form>
    )
  }
}

export default PayForm