import "./paymentForm.scss"
import React from "react"
import { Title } from "../../Layout/Title/title"
import Alert from "../Alert"
import visa from "../../../images/icons/visa.svg"
import master from "../../../images/icons/master.svg"
import PayForm from "../PayForm"

export default class PaymentForm extends React.Component {

  constructor(props) {
    super(props);
  }

  
  render () {
    return (
      <div className={`paymentForm__wrapper`}>
        <div className={`paymentForm__header`}>
        <Title regular content={`Last Step To Download Your Document`}/>
        </div>
        <div className={`paymentForm__body`}>
          <Alert regular timer content=" Left to Download Your Document" />
          <div className={`paymentForm__title`}>
            <Title form content={`Credit/Debit Card Information`} />
            <div className={`paymentForm__cards`}>
              <img src={visa} />
              <img src={master} />
            </div>
          </div>
            <PayForm 
              form={this.props.form}
              structure={this.props.structure}
              updateForm={this.props.updateForm} 
              application={this.props.application} 
              updateApplication={this.props.updateApplication}
              />
        </div>
  
        <div className={`paymentForm__footer`}>
          <p className={`paymentForm__buttonText`}>Your personal information is securely encrypted by Secure Sockets Layer (SSL) software.</p>
        </div>
      </div>
    )
  }

}