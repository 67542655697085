import React from "react"
import "./sideSummary.scss"
import { Title } from "../../Layout/Title/title"
import Alert from "../../Form/Alert"
import ssl from "../../../images/payment/ssl.png"
import comodo from "../../../images/payment/comodo_secure.png"
import cert from "../../../images/payment/cert.png"
import money from "../../../images/payment/money.png"

export const SideSumary = props => {
    return (
        <div className="m-sideSummary">
            <div className="m-sideSummary__wrapper">
                <div className="m-sideSummary__header">
                    <Title form content={`Order Summary`} />
                </div>
                <div className="m-sideSummary__body">
                    <p className="m-sideSummary__txt">
                        Application Reference: { props.applicationReference}
                    </p>
                    <p className="m-sideSummary__txt m-sideSummary__txt-strong">
                        Total amount: ${ props.applicationPrice }
                    </p>
                </div>
                <div className="m-sideSummary__footer">
                    <Alert icon content={`<span className="">Important</span>: This does not include any applicable government filing fees.`} />
                </div>
            </div>
            <div className="m-sideSummary__wrapper m-sideSummary__light">
                <div className="m-sideSummary__body">
                    <p>
                        Create and edit passport documents and export them to PDF in minutes.
                    </p>
                    <p>
                        Make a one-time payment to export your passport forms now.
                    </p>
                    <p>
                        Pay with confidence. This site is SSL secured to keep your card information safely encrypted.
                    </p>
                </div>
                <div className="m-sideSummary__footer">
                    <img src={ssl} />
                    <img src={comodo} />
                    <img src={money} />
                    <img src={cert} />
                </div>
            </div>
        </div>
    )
  }
